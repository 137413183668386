import React from 'react';

import { useSpring, animated } from 'react-spring';

import { Menu } from './Menu';

interface CollapsibledMenuProps {
  isExpanded: boolean;
  onMenuClick: () => void;
}

export const CollapsibledMenu: React.FC<CollapsibledMenuProps> = ({ isExpanded, onMenuClick }) => {
  const { open } = useSpring({ open: isExpanded ? 0 : 1 });

  if (isExpanded === true) {
    return (
      <animated.div
        className='collapsed-menu'
        style={{
          transform: open.interpolate({
            range: [0, 0.2, 0.4, 0.6, 1],
            output: [0, -20, -50, -120, -200],
          }).interpolate(openValue => `translate3d(0, ${openValue}px, 0)`)
        }}
      >
        <Menu menuClass='nav-links' onMenuClick={onMenuClick}/>
      </animated.div>
    );
  }
  return null;
}