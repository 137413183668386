import React from 'react';

interface Props {
  isExpanded: boolean;
  onToggleExpanded: () => void;
}

export const BurgerMenu: React.FC<Props> = (props: Props) => {
  return (
    <div className='burger-menu-wrapper' onClick={props.onToggleExpanded}>
      <div className={props.isExpanded ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </div>
  );
}