import React from 'react';
import { Link } from 'gatsby';

import ScrollSpy from 'react-scrollspy';
import scrollToElement from 'scroll-to-element';

import { NAVBAR_OFFSET, SECTION_PADDING } from '../../config';

interface MenuProps {
  menuClass: string;
  onMenuClick: () => void;
}

export const Menu: React.FC<MenuProps> = ({ menuClass, onMenuClick }) => {
  const handleMenuClick = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {
    if (typeof window !== 'undefined') {
      // if we are on the home page, scroll to the specified section
      if (window.location.pathname === '/') {
        // if (e) {
        //   e.preventDefault();
        // }
        scrollToElement(target, {
          offset: (NAVBAR_OFFSET + SECTION_PADDING), // header offset + section padding (py-8 or 32px)
          duration: 1000,
        });
        // invoke callback
        onMenuClick();
      }
    }
  };

  return (
    <ScrollSpy className={menuClass} items={['home', 'services', 'projects']} currentClassName='active' offset={NAVBAR_OFFSET}>
      <li><Link to='/' onClick={e => handleMenuClick(e, '#home')}>Home</Link></li>
      <li><Link to='/#services' onClick={e => handleMenuClick(e, '#services')}>Services</Link></li>
      <li><Link to='/#projects' onClick={e => handleMenuClick(e, '#projects')}>Projects</Link></li>
      <li><Link to='/contact' activeClassName='active'>Contact</Link></li>
      <li><Link to='/blog' activeClassName='active'>Blog</Link></li>
    </ScrollSpy>
  );
}