import React, { useState, useRef } from 'react';

import { useSpring, animated, config } from 'react-spring';

import { useOnClickOutside } from '../../hooks';

import { Logo } from './Logo';
import { BurgerMenu } from './BurgerMenu';
import { Menu } from './Menu';
import { CollapsibledMenu } from './CollapsibleMenu';

export const NavBar: React.FC = () => {
  const [isExpanded, setExpanded] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(navRef, () => {
    if (isExpanded) {
      setExpanded(false);
    }
  });

  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  const handleToggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const collapseMenu = () => {
    setExpanded(false);
  };

  return (
    <div ref={navRef}>
      <animated.nav className='navbar' style={barAnimation}>
        <div className='w-full container mx-auto flex justify-between items-center my-4'>
          <Logo />
          <animated.div style={linkAnimation}>
            <Menu menuClass='menu' onMenuClick={collapseMenu} />
          </animated.div>
          <div className='md:hidden mr-4'>
            <BurgerMenu isExpanded={isExpanded} onToggleExpanded={handleToggleExpanded} />
          </div>
        </div>
      </animated.nav>
      <CollapsibledMenu isExpanded={isExpanded} onMenuClick={collapseMenu} />
    </div>
  );
}